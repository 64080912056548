<template>
    <div>
        <div class="popup__header df" style="height: 100px;">
            <div class="title">Удалить расход?</div>
        </div>


        <div class="popup__content">
            <div class="text-bold text-size-ml mt8">
                {{ number_format(spend.sum) }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
            </div>
            <div class="text-italic text-color-grey mt8" v-html="spend.comment"></div>
            <div class="df df-space-between mt40">
                <submit-btn  tag="button" @click="removeSpend()" :type="form.btnType">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Внимание! Отменить это действие будет невозможно!
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import {number_format} from "@/helpers/formatted";

    export default {
        name: "RemoveExpense",
        components: {
            SubmitBtn,
            TextareaField,
        },
        props: {
            spend: {
                default: function(){
                    return false;
                }
            }
        },
        methods: {
            removeSpend() {
                this.$store.dispatch('fin/removeSpend', {type: 'removeSpend', id: this.spend.id}).then(() => {
                    this.$emit('removed');
                    this.$store.commit('popups/close', {name: this.popups.removeExpense.name});
                });
            },

            number_format: function (v) {
                return number_format(v, 0, '', ' ')
            },
        },
        data: function () {
            return {
                form: {
                    btnType: 'normal',
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    checkbox2: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    }
                },

            }
        },
        mounted() {


        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
